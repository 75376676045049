import { Amenities } from '@/components/property/Amenities'
import { BedroomDetails } from '@/components/property/BedroomDetails'
import { useSettingContext } from '@/contexts/settings'
import { PropertyDescription } from '@/components/property/PropertyDescription'
import { getPropertyDescription } from '@/components/property/utils'
import { Box, Divider, useStyleConfig, VStack } from '@chakra-ui/react'
import React from 'react'

/**
 *
 * @param {DetailedProperty} property
 * @param {boolean} isOpen
 * @returns {JSX.Element}
 * @constructor
 */
export const PropertySummary = ({ property, isOpen = true }) => {
  const description = getPropertyDescription(property)
  const { settings } = useSettingContext()
  const displayBedroomDetails = settings.displayBedroomDetails

  const style = useStyleConfig('Box', { variant: 'mobileProperty' })
  if (!isOpen) return null
  const amenities = property.amenities
  const bedroom_details = property.bedroom_details === '[]' || property.bedroom_details === null ? '' : property.bedroom_details
  if (!bedroom_details && !amenities.length && !description) return null
  return (
    <Box sx={{ ...style, width: 'full' }}>
      <VStack divider={<Divider />} spacing={10} align="stretch">
        {displayBedroomDetails && !!bedroom_details.length && <BedroomDetails bedroom_details={bedroom_details} />}
        {!!amenities.length && <Amenities amenities={amenities} />}
        {!!description && <PropertyDescription description={description} />}
      </VStack>
    </Box>
  )
}
