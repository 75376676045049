import React from 'react'
import { Box, Grid, Text, Icon, Heading } from '@chakra-ui/react'
import BedIcon from 'icons/bed.svg'
import { useTranslation } from 'next-i18next'

const BedroomBox = ({ bedroom }) => (
  <Box borderRadius="lg" border="1px solid #ddd" p={4} w="195px">
    <Grid templateRows="repeat(3, 1fr)" gap={2}>
      <Grid templateColumns="repeat(4, 1fr)" gap={1}>
        {bedroom.beds.map((bed, i) => (
          <React.Fragment key={i}>
            {Array.from({ length: bed.count }, (_, index) => (
              <Icon key={index} as={BedIcon} w={4} h={4} fill="gray.500" />
            ))}
          </React.Fragment>
        ))}
      </Grid>
      <Text fontSize="md" fontWeight="bold">
        {bedroom.name}
      </Text>
      <Text fontSize="sm" color="gray.500">
        {bedroom.beds.map((bed, i) => (
          <span key={i}>
            {bed.bed_type} ({bed.count})
          </span>
        ))}
      </Text>
    </Grid>
  </Box>
)

export const BedroomDetails = (bedroom_details) => {
  const { t } = useTranslation('property')
  const bedroomDetails = eval(bedroom_details['bedroom_details'])

  return (
      <Box>
        <Heading as="p" size="md" mb={5} mt={3}>
          {t('rooms-and-beds')}
        </Heading>
  
        <Grid templateColumns="repeat(auto-fill, minmax(195px, 1fr))" gridAutoRows="minmax(130px, auto)" gap={1} >
          {bedroomDetails.map((bedroom, i) => (
            <BedroomBox key={i} bedroom={bedroom} />
          ))}
        </Grid>
      </Box>
  )
}
